<template>
<div>
  <b-modal :id="id"
           :title="title"
           ok-title="Välj"
           :ok-disabled="selectedPeople.length == 0"
           @ok="confirmSelected"
           @hidden="resetForm"
           size="lg">
    <b-form @submit="search">
      <b-form-row>
        <b-col>
          <b-input
            id="inline-form-input-firstname"
            ref="inline-form-input-firstname"
            placeholder="Förnamn"
            class="m-2 mr-sm-2 mb-sm-0"
            :disabled="criteria.lastName.length > 0"
            description="Ange förnamnets början (en eller fler bokstäver)"
            v-model="criteria.firstName"
            ></b-input>
        </b-col>
        <b-col>
          <b-input
            id="inline-form-input-lastname"
            ref="inline-form-input-lastname"
            placeholder="Efternamn"
            class="m-2 mr-sm-2 mb-sm-0"
            :disabled="criteria.firstName.length > 0"
            description="Ange efternamnets början (en eller fler bokstäver)"
            v-model="criteria.lastName"
            ></b-input>
        </b-col>
      </b-form-row>
      <b-form-text class="m-2">
        Du kan söka på början av förnamnet eller efternamnet (tyvärr inte bägge). Känslig för stora/små bokstäver.
      </b-form-text>
      <b-form-row>
        <b-col>
          <b-form-radio-group
            id="memberOrProspect-radios"
            v-model="memberOrProspect"
            class="m-2"
            :options="searchOptions"
            button-variant="outline-primary"
            buttons>
          </b-form-radio-group>
        </b-col>
        <b-col>

          <b-form-select
            v-if="allowChangingPeriod"
            id="inline-form-input-period"
            ref="inline-form-input-period"
            class="m-2 mr-sm-2 mb-sm-0"
            v-model="criteria.period"
            :options="allPeriods"
            ></b-form-select>
        </b-col>
      </b-form-row>
      <b-form-text class="m-2">
        Du kan välja att endast söka på registrerade medlemmar eller endast nyanmälda denna termin (som ännu inte betalat)
      </b-form-text>

      <b-form-row>
        <b-col>
          <b-form-group v-if="memberOrProspect == 'prospect'">
            <b-form-select
              id="inline-form-input-group"
              ref="inline-form-input-group"
              class="m-2 mr-sm-2 mb-sm-0"
              v-model="criteria.group"
              :options="allGroups"
              ></b-form-select>
          </b-form-group>

          <b-form-group v-if="memberOrProspect == 'member'">
            <b-form-select
              id="inline-form-input-rank"
              ref="inline-form-input-rank"
              class="m-2 mr-sm-2 mb-sm-0"
              v-model="criteria.rank"
              :options="allRanks"
              ></b-form-select>
          </b-form-group>

        </b-col>
        <b-col>

          <b-button type="submit" class="float-right m-2 mr-sm-2 mb-sm-0" variant="primary" :disabled="searching">Sök</b-button>
        </b-col>
      </b-form-row>
    </b-form>
    <hr v-if="this.results && this.results.length" />
    <b-list-group v-if="this.results && this.results.length" class="mt-3">
      <b-list-group-item
        v-for="result in this.results"
        :key="result.id"
        @click="toggleSelection(result)"
        :active="selectedPeople.map(p => p.id).includes(result.id)"
        :disabled="disableIds.includes(result.id)"
        class="d-flex justify-content-between align-items-center">
        <div>
          <font-awesome-icon :icon="['fas', 'user']" ></font-awesome-icon>
          {{result.firstName}} {{result.lastName}} (-{{ yearOfBirth(result) }})
        </div>
        <RankImage v-if="result.rank" :rank="result.rank" />
      </b-list-group-item>
    </b-list-group>
    <p v-if="this.results && this.results.length == 0">Ingen träff</p>
  </b-modal>
</div>
</template>

<script>
import { db } from '@/firebaseConfig';
import RankImage from '@/components/RankImage.vue'
import { rankNames } from '@/utils/ranks.js'
import { collection, getDocs, query, where } from 'firebase/firestore'

export default {
  props: {
    id: String,    // used to set the id of the b-modal element
    title: {       // is being set as a header of the modal dialog
      type: String,
      default: 'Sök person'
    },
    disableIds: {
      type: Array,
      default: () => []
    },
    allowChangingPeriod: {
      type: Boolean,
      default: false
    }
  },
  components: { RankImage },
  data() {
    return {
      criteria: {firstName: '', lastName: '', group: null, rank: null, period: 'ht-2024'},
      results: null,
      selectedPeople: [],
      allRanks: [{ value: null, text: 'Alla grader'},
                 ...rankNames.map((name, index) => { return { value: index, text: name }})],
      allGroups: [
        { value: null, text: 'Alla grupper'},
        { value:'mon-nyb', text:'Mon nybörjare (9-12 år)' },
        { value:'mon-gul', text:'Mon gul-vita'},
        { value:'mon-orange', text:'Mon orange-vita'},
        { value:'mon-gron', text:'Mon grön-vita'},
        { value:'mon-bla', text:'Mon blå-vita'},
        { value:'mon-brun', text:'Mon brun-vita'},
        { value:'ung-nyb', text:'Ungdomar nybörjare (13-15 år)'},
        { value:'ung-gul', text:'Ungdomar fd. mon'},
        { value:'ung-gul', text:'Ungdomar gul'},
        { value:'ung-orange', text:'Ungdomar orange'},
        { value:'ung-gron', text:'Ungdomar grön'},
        { value:'nyb', text:'Vuxna nybörjare (16 år och äldre)'},
        { value:'nyb-forts', text:'Vuxna nybörjare fortsättning'},
        { value:'gul', text:'Gul'},
        { value:'orange', text:'Orange'},
        { value:'gron', text:'Grön'},
        { value:'bla', text:'Blå'},
        { value:'brun', text:'Brun'},
        { value:'1dan', text:'1 dan'},
        { value:'2danplus', text:'2 till 8 dan'}],
      allPeriods: [
        { value: '2020-ht', text: '2020 HT'},
        { value: '2021-vt', text: '2021 VT'},
        { value: '2021-ht', text: '2021 HT'},
        { value: '2022-vt', text: '2022 VT'},
        { value: '2022-ht', text: '2022 HT'},
        { value: 'vt-2023', text: '2023 VT'},
        { value: 'ht-2023', text: '2023 HT'},
        { value: 'vt-2024', text: '2024 VT'},
        { value: 'ht-2024', text: '2024 HT'},
        { value: 'vt-2025', text: '2025 VT'}],
      memberOrProspect: '',
      searching: false
    }
  },
  computed: {
    searchOptions() {
      return [{ text: 'Medlem', value: 'member' }, { text: 'Nyanmäld ' + this.criteria.period, value: 'prospect'} ]
    }
  },
  watch: {
    memberOrProspect() {
      this.resetResults();
    },
    criteria: {
      handler() {
        this.resetResults();
      },
      deep: true
    }
  },
  methods: {
    yearOfBirth(person) {
      if (person.birthdate) {
        return person.birthdate.substring(2,4)
      } else {
        return person.personalIdNumber.substring(2,4)
      }
    },
    async search(event) {
      event.preventDefault();
      this.searching = true;
      const tempResults = [];

      const queryConstraints = [];
      if (this.criteria.firstName.length) {
        queryConstraints.push(where('firstName', '>=', this.criteria.firstName));
        queryConstraints.push(where('firstName', '<=', this.criteria.firstName + '\uf8ff'));
      }
      if (this.criteria.lastName.length) {
        queryConstraints.push(where('lastName', '>=', this.criteria.lastName));
        queryConstraints.push(where('lastName', '<=', this.criteria.lastName + '\uf8ff'));
      }

      // Search for members
      if (this.memberOrProspect != 'prospect') {
        if (this.criteria.rank) {
          queryConstraints.push(where('rank', '==', this.criteria.rank));
        }
        const q = query(collection(db, 'members'), ...queryConstraints)
        let membersQuerySnapshot = await getDocs(q);
        membersQuerySnapshot.forEach(doc => {
          // Since Firebase has no way of filtering on missing fields, filter here instead
          if (!(this.criteria.rank == 0 && doc.data().rank != undefined)) {
            tempResults.push({ ...doc.data(), id: doc.id, isProspect: false});
          }
        });
      }

      // Search for prospects
      if (this.memberOrProspect != 'member') {
        queryConstraints.push(where('period', '==', this.criteria.period));
        if (this.criteria.group) {
          queryConstraints.push(where('group', '==', this.criteria.group))
        }
        const q = query(collection(db, 'prospects'), ...queryConstraints)
        let prospectsQuerySnapshot = await getDocs(q);
        prospectsQuerySnapshot.forEach(doc => {
          const prospect = { ...doc.data(), id: doc.id, isProspect: true }
          if (prospect.memberId) {
            // Prospect is already member, skip this
          } else {
            tempResults.push({ ...doc.data(), id: doc.id, isProspect: true});
          }
        });
      }

      this.results = tempResults;
      this.searching = false;
    },
    toggleSelection(person) {
      const index = this.selectedPeople.findIndex(p => p.id == person.id)
      if (index == -1) {
        this.selectedPeople.push(person)
      } else {
        this.selectedPeople.splice(index, 1)
      }
    },
      confirmSelected() {
        this.selectedPeople.forEach(person => this.$emit('person', person))
        this.resetForm()
      },
      resetResults() {
        this.results = null
      },
      resetForm() {
        this.selectedPeople = []
        this.results = []
        this.criteria = {firstName: '', lastName: '', period: 'ht-2024'}
      }
    }
  }
</script>
